<template>
  <fragment>
    <section class="position-relative z-index-1 margin-y-lg">
      <div class="container max-width-lg">
        <div class="grid">
          <div class="bg-contrast-lower flex items-center col-6@md">
            <div class="padding-md padding-x-lg@lg padding-y-xxl@lg">
              <div class="text-sm color-contrast-medium margin-bottom-xxs">
                {{ content.type }}
              </div>
              <div class="text-component">
                <h1 class="margin-bottom-xs">{{ content.title }}</h1>
                <h6 class="color-contrast-medium text-sm margin-bottom-sm">
                  {{ formatDate(content) }} <br />{{ content.locatie }}
                </h6>
                <p>
                  {{ content.beschrijving }}
                </p>
              </div>

              <div class="margin-top-sm">
                <div class="flex flex-wrap gap-sm items-center"></div>
              </div>
            </div>
          </div>
          <figure class="col-6@md">
            <img
              class="block width-100% height-100% object-cover"
              :src="content.header_afbeelding?.permalink"
              alt="Image description"
            />
          </figure>
        </div>
      </div>
    </section>
    <section>
      <div class="container max-width-lg">
        <div class="grid gap-lg">
          <div class="col-12">
            <router-link to="/activiteiten" class="text-decoration-none">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="600px"
                height="100%"
                viewBox="0 0 487 487"
                xml:space="preserve"
                class="icon align-middle rotate-180 margin-right-sm"
              >
                <g>
                  <path
                    d="M482,234.839l-116.5-67.261c-4.783-2.761-10.898-1.122-13.66,3.66c-2.762,4.783-1.122,10.898,3.66,13.66l84.213,48.62H10 c-5.523,0-10,4.478-10,10c0,5.521,4.477,10,10,10h429.646L355.5,302.101c-4.783,2.762-6.422,8.877-3.66,13.66 c1.853,3.208,5.213,5.002,8.669,5.002c1.696,0,3.416-0.434,4.991-1.342L482,252.159c3.094-1.786,5-5.088,5-8.66 C487,239.927,485.094,236.625,482,234.839z"
                  ></path>
                </g>
              </svg>
              Terug naar overzicht
            </router-link>
          </div>
          <div class="col-7@md">
            <div
              class="grid gap-md"
              v-for="item in content.pagina_content"
              :key="item.text"
            >
              <div v-if="item.type == 'text'">
                <div class="col-12">
                  <div class="text-component" v-html="item.text"></div>
                </div>
              </div>
            </div>

            <div class="text-component margin-y-md">
              <h3 class="text-md">Sprekers</h3>
            </div>
            <dl v-if="content.sprekers.length">
              <div
                v-for="spreker in content.sprekers"
                :key="spreker.naam"
                class="bg radius-md padding-sm border-left border-3 border-primary shadow-xs margin-bottom-sm"
              >
                <dt class="font-bold margin-bottom-xxs text-sm">
                  {{ spreker.naam }}
                </dt>
              </div>
            </dl>
            <div v-else>
              <p>Er zijn nog geen sprekers bekend.</p>
            </div>
          </div>
          <div class="col-5@md">
            <div class="bg-contrast-lower padding-sm shadow-sm">
              <div class="text-component" v-if="user">
                <h3 class="text-md">Inschrijven</h3>
                <p v-if="content.type == 'symposium'" class="text-sm">
                  Kosten voor dit weekend bedragen <strong>€300,-</strong> voor
                  niet leden en <strong>€100,-</strong> voor OCG leden.
                </p>
                <p v-if="payments.length == 0 && userShouldPay" class="text-sm">
                  Meld u aan voor deze bijeenkomst. Om u in te schrijven dient u uw lidmaatschap
                  te activeren. In uw
                  <router-link to="/account">account</router-link> kunt u ons
                  machtigen om jaarlijks de lidmaatschapskosten te innen. 
                </p>
                <div v-if="!inschrijvingExists">
                  <p v-if="payments.length == 0 && !userShouldPay" class="text-sm">
                    Deze bijeenkomst is gratis toegankelijk voor u.
                  </p>
                </div>
<div v-if="inschrijvingExists">
  <p class="text-sm">
    U bent al ingeschreven voor deze bijeenkomst. Neem contact
    op met onze
    <a href="mailto:info@oostelijkcardiologengenootschap.nl">
      administratie
    </a>
    om uw inschrijving te wijzigen.
  </p>
</div>
<div v-else>
  <button @click="showModal" class="btn btn--sm btn--primary">
    Direct inschrijven
  </button>
</div>

    
              </div>

              <div class="text-component" v-if="!user">
                <h3 class="text-md">Inschrijven</h3>
                <p v-if="content.type == 'symposium'" class="text-sm">
                  Kosten voor dit weekend bedragen <strong>€300,-</strong> voor
                  niet leden en <strong>€100,-</strong> voor OCG leden.
                </p>
                <p v-if="content.type == 'bijeenkomst'" class="text-sm">
                  Deze bijeenkomst is gratis toegankelijk voor OCG Leden. Bent u
                  reeds lid, dan kunt u hier
                  <router-link to="/login">inloggen</router-link>.
                </p>

                <p class="text-sm">
                  Om u aan te melden voor deze bijeenkomst dient u lid te worden
                  van het Oostelijk Cardiologen Genootschap. Wilt u lid worden?
                  Vraag dan een account aan via het
                  <router-link to="/registreren"
                    >registratie formulier.</router-link
                  >
                  <br />
                  <br />
                  Vervolgens kunt u zich inschrijven voor onze bijeenkomsten.
                </p>

                <button
                  v-if="$store.state.userProfile.email"
                  aria-controls="modal-form"
                  @click="showModal"
                  class="btn btn--sm btn--primary"
                >
                  Direct inschrijven
                </button>

                <button
                  v-if="!$store.state.userProfile.email"
                  aria-controls="inschrijfdialog"
                  class="btn btn--sm btn--primary"
                >
                  Aanmelden en inschrijven
                </button>
    
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="inschrijfdialog" class="dialog js-dialog" data-animation="on">
      <div
        class="dialog__content max-width-xxs"
        role="alertdialog"
        aria-labelledby="dialog-title-1"
        aria-describedby="dialog-description-1"
      >
        <div class="text-component">
          <h4 id="dialog-title-1">Heeft u een account?</h4>
          <p id="dialog-description-1">
            Om van het gereduceerde tarief als OCG lid gebruik te maken dient u
            zich als lid registreren of moet u ingelogd te zijn.
            <br />
            <br />
            <router-link
              class="btn btn--primary margin-right-sm btn--sm"
              to="/login"
              >Inloggen</router-link
            >
            <router-link class="btn btn--subtle btn--sm" to="/registreren"
              >Registreren</router-link
            >
          </p>
        </div>

        <footer v-if="content.type == 'bijeenkomst'" class="margin-top-md">
          <div class="flex justify-end gap-xs flex-wrap">
            <router-link
              to="/login"
              class="btn btn--subtle btn--sm js-dialog__close"
              >Inloggen</router-link
            >
            <router-link
              to="/registreren"
              class="btn btn--primary btn--sm js-dialog__close"
              >Registreren</router-link
            >
          </div>
        </footer>

        <footer v-if="content.type == 'symposium'" class="margin-top-md">
          <div class="flex justify-end gap-xs flex-wrap">
            <router-link
              to="/login"
              class="btn btn--subtle btn--sm js-dialog__close"
              >Inloggen</router-link
            >
            <router-link
              to="/registreren"
              class="btn btn--subtle btn--sm js-dialog__close"
              >Registreren</router-link
            >
            <button
              class="btn btn--primary btn--sm js-dialog__close"
              aria-controls="modal-form"
            >
              Doorgaan
            </button>
          </div>
        </footer>
      </div>
    </div>

    <div
      :class="modalVisible ? 'modal--is-visible' : ''"
      class="modal modal--animate-scale flex flex-center bg-contrast-higher bg-opacity-90% padding-md js-modal"
      id="modal-form"
    >
      <div
        class="modal__content width-100% max-width-sm max-height-100% overflow-auto padding-md bg radius-md shadow-md"
        role="alertdialog"
        aria-labelledby="modal-form-title"
        aria-describedby="modal-form-description"
      >
        <div class="text-component margin-bottom-md">
          <h3 id="modal-form-title">Inschrijven voor: {{ content.title }}</h3>
          <p id="modal-form-description">
            Wij schrijven u in met onderstaande gegevens. Zijn deze gegevens
            onjuist? Pas deze aan in uw
            <router-link to="/account">profiel</router-link>.
          </p>
        </div>

        <div class="margin-bottom-sm">
          <div class="grid gap-xs">
            <div class="col-6@md">
              <label
                class="form-label margin-bottom-xxxs"
                for="input-first-name"
                >Voornaam</label
              >
              <input
                class="form-control width-100%"
                type="text"
                name="voornaam"
                required=""
                disabled
                :value="$store.state.userProfile.voornaam"
                id="input-first-name"
              />
            </div>

            <div class="col-6@md">
              <label class="form-label margin-bottom-xxxs" for="input-last-name"
                >Achternaam</label
              >
              <input
                class="form-control width-100%"
                type="text"
                required=""
                disabled
                name="achternaam"
                :value="$store.state.userProfile.achternaam"
                id="input-last-name"
              />
            </div>

            <div class="col-6@md">
              <label
                class="form-label margin-bottom-xxxs"
                for="input-first-name"
                >Functie</label
              >
              <input
                class="form-control width-100%"
                type="text"
                name="functie"
                required=""
                disabled
                :value="$store.state.userProfile.functie"
                id="input-first-name"
              />
            </div>
            <div class="col-6@md">
              <label class="form-label margin-bottom-xxxs" for="input-last-name"
                >Werkzaam bij</label
              >
              <input
                class="form-control width-100%"
                type="text"
                name="organisatie"
                disabled
                :value="$store.state.userProfile.organisatie"
                id="input-last-name"
              />
            </div>
            <div class="col-6@md">
              <label class="form-label margin-bottom-xxxs" for="input-last-name"
                >BIG nummer</label
              >
              <input
                class="form-control width-100%"
                type="text"
                name="big_nummer"
                disabled
                :value="$store.state.userProfile.big_nummer"
                id="input-last-name"
              />
            </div>

            <div class="col-6@md">
              <label class="form-label margin-bottom-xxxs" for="input-email"
                >E-mail adres</label
              >
              <input
                class="form-control width-100%"
                type="email"
                name="email"
                required=""
                id="input-email"
                disabled
                :value="$store.state.userProfile.email"
                placeholder="naam@domein.nl"
              />
            </div>
            <div class="col-12@md">
              <textarea
                class="form-control width-100%"
                placeholder="Vragen & opmerkingen"
                v-model="opmerkingen"
              ></textarea>
            </div>
            <div class="col-4@md"></div>
            <div class="col-4@md">
              <div class="text-center padding-sm rounded-md bg-success-lighter" v-if="inschrijvingSuccess">
                  <div class="">
                      <p class="color-success-dark">Bedankt voor je inschrijving, je ontvangt spoedig een mail met de bevestiging.</p>
                  </div>
              </div>
              <div class="text-center" v-else>
                <button
                  @click.prevent="createInschrijving"
                  class="reset btn-states width-100% btn btn--primary"
                  :class="loading && 'btn-states--state-b'"
                >
                  <span class="btn-states__content-a padding-x-sm">
                    Inschrijving bevestigen
                  </span>

                  <span class="btn-states__content-b inline-flex flex-center">
                    <svg
                      class="icon icon--is-spinning margin-right-xxxs"
                      aria-hidden="true"
                      viewBox="0 0 16 16"
                    >
                      <title>Loading</title>
                      <g
                        stroke-width="1"
                        fill="currentColor"
                        stroke="currentColor"
                      >
                        <path
                          d="M.5,8a7.5,7.5,0,1,1,1.91,5"
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                    </svg>
                    <span>Laden</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="text-component">
          <div class="text-center">
            <p class="text-xs color-contrast-medium">
              Door u in te schrijven gaat u akkoord met onze
              <a href="/documenten/privacyverklaring_ocg.pdf"
                >privacy verklaring</a
              >.
            </p>
          </div>
        </div>
      </div>

      <button
        @click="hideModal"
        class="reset modal__close-btn modal__close-btn--outer js-modal__close js-tab-focus cursor-pointer"
      >
        <svg class="icon icon--sm" viewBox="0 0 24 24">
          <title>Close modal window</title>
          <g
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="3" y1="3" x2="21" y2="21" />
            <line x1="21" y1="3" x2="3" y2="21" />
          </g>
        </svg>
      </button>
    </div>
  </fragment>
</template>

<script>
const fb = require("../firebaseConfig.js");
import { Fragment } from "vue-fragment";
import firebase from "firebase";
import axios from "axios";
import moment from "moment";
import "moment/locale/nl"; // without this line it didn't work
moment.locale("nl");

const inschrijvingCollection = firebase
  .firestore()
  .collection("inschrijvingCollection");

export default {
  name: "Register",
  components: {
    Fragment,
  },
  data() {
    return {
      user: null,
      updated: false,
      payments: [],
      opmerkingen: "",
      querySnapshot: null,
      inschrijvingExists: false,
      inschrijvingSuccess: false,
      loading: false,
      modalVisible: false,
    };
  },

  mounted() {
    this.loadScripts();
    this.isSignedIn();
  },
  created() {
    setTimeout(() => {

      if (this.$store.state.userProfile.email) {
       // this.getCustomerPayments();
        this.checkInschrijvingExists();
      }
    }, 1000);
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    },
    hideModal() {
      this.modalVisible = false;
    },
    isSignedIn() {
      let firebaseUser = null;

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.user = user;
        } else {
          this.user = null;
        }
      });

      return firebaseUser;
    },
    async createInschrijving() {
      this.loading = true;
      const user = this.$store.state.userProfile;
      const id = this.content.id;

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const datum = new Date(this.content.datum).toLocaleDateString('nl-NL', options);
    

      
      try {

        // Check if the document with the given email and bijeenkomst_id exists
        if (this.querySnapshot.empty) {
          
          // Add new registration
          await inschrijvingCollection.add({
            bijeenkomstId: this.content.id,
            bijeenkomst: this.content.title,
            bijeenkomstSlug: this.content.slug,
            datum: this.content.datum,
            opmerkingen: this.opmerkingen,
            ...user,
          });
        } else {
        
          // Update existing document
          this.querySnapshot.forEach(async (doc) => {
            await doc.ref.update({
              bijeenkomstId: this.content.id,
              bijeenkomst: this.content.title,
              datum: datum,
              opmerkingen: this.opmerkingen,
              ...user,
            });
            console.log("Document updated successfully.");
          });
        }

        // Send email confirmation
        axios
          .post("/api/eventRegistermail", {
            bijeenkomstId: this.content.id,
            bijeenkomst: this.content.title,
            datum: this.content.datum,
            opmerkingen: this.opmerkingen,
            ...user,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log("Mail sent");
              this.inschrijvingSuccess = true;
              setTimeout(() => {
              //  this.hideModal();
              }, 5000);
            }
          })
          .catch((error) => {
            console.error(error);
          });

       
        this.loading = false;
        this.updated = true;
      } catch (error) {
        console.error("Error during registration: ", error);
        this.inschrijvingSuccess = false;
        this.loading = false;
      }
    },
    async checkInschrijvingExists() {
      this.querySnapshot = await inschrijvingCollection
        .where("email", "==", this.$store.state.userProfile.email)
        .where("bijeenkomstId", "==", this.content.id)
        .get();
      

      if (this.querySnapshot.empty) {
        this.inschrijvingExists = false;
      } else {
        this.inschrijvingExists = true;
      }
    },
    formatDate(event) {
      const starttijd = moment(event.datum).utc().format("LLLL");

      if (event.heeft_eindtijd_datum) {
        const eindtijd = moment(event.einddatum).utc().format("LLLL");
        return `${starttijd.charAt(0).toUpperCase() + starttijd.slice(1)} tot ${
          eindtijd.charAt(0).toUpperCase() + eindtijd.slice(1)
        }`;
      } else {
        return starttijd.charAt(0).toUpperCase() + starttijd.slice(1);
      }
    },
  },
  computed: {
    content() {
      const pageContent = this.$store.state.siteContent.filter((page) => {
        return page.uri == "/symposia";
      });

      const eventContent = pageContent[0].children.filter((event) => {
        return event.slug == this.$route.params.slug;
      });

      return eventContent[0];
    },
  },
};
</script>

<style scope lang="scss">
li {
  list-style-position: inside !important;
  p {
    display: inline-flex;
    width: 94%;
  }
}
</style>
